import React from "react";
import CryptoJS from "crypto-js";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Headline from "../components/headline";
import SearchForm from "../components/search-form";
import Features from "../components/features";
import News from "../components/news";
import GridCardSlider from "../components/grid-card-slider";
import { queryMenu } from "../graphql/menu";
import { categoriesNotEqual } from "../graphql/category";
import {
  articlesByCategory,
  popularArticles as popularArticlesQuery,
} from "../graphql/article";
import { headlines } from "../graphql/headline";
import features from "../graphql/feature";
import { formatGQLQuery } from "../utils";
import contactAndAddress from "../graphql/footer";

const IndexPage = ({ serverData, ...props }) => {
  const {
    menu,
    headlines,
    popularArticles,
    features,
    recentNews,
    categories,
    contact,
    address,
    additionalSearchData = null,
  } = serverData;
  return (
    <>
      <Seo />
      <Layout menu={menu} contact={contact} address={address} {...props}>
        <main>
          <div className="container overflow-hidden">
            <Headline
              data={headlines}
              popularArticles={popularArticles}
              {...props}
            />
            <SearchForm
              {...props}
              additionalSearchData={additionalSearchData}
            />
            <Features data={features} {...props} />
            <News data={recentNews} {...props} />
            <GridCardSlider data={categories} {...props} />
          </div>
        </main>
      </Layout>
    </>
  );
};
export async function getServerData(context) {
  try {
    const today = new Date();
    const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
    const isoThirtyDaysAgo = thirtyDaysAgo.toISOString().toString();
    const response = await fetch(process.env.GRAPHQL_URL_SSR, {
      cache: "no-cache",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: formatGQLQuery(
          [
            queryMenu,
            categoriesNotEqual,
            articlesByCategory,
            headlines,
            popularArticlesQuery,
            features,
            contactAndAddress,
          ],
          [
            { key: "$articlesByCategory", type: "String!" },
            { key: "$categoriesNotEqual", type: "String!" },
            { key: "$articlesByCategoryLimit", type: "Int!" },
            { key: "$popularArticlesLimit", type: "Int!" },
            { key: "$popularArticlesMaxDate", type: "DateTime!" },
          ]
        ),
        variables: {
          articlesByCategory: "berita",
          articlesByCategoryLimit: 9,
          categoriesNotEqual: "berita",
          popularArticlesLimit: 5,
          popularArticlesMaxDate: isoThirtyDaysAgo,
        },
      }),
    });

    let additionalSearchData;

    if (context.query) {
      const key = context.query?.search;
      const queryValue = context.query?.q;
      switch (key) {
        case "estimation": {
          if (!queryValue) break;
          const headersRequest = {
            "Content-Type": "application/json",
            "x-key": process.env.XKEY_SISKOHAT,
          };
          const bytes = CryptoJS.AES.decrypt(
            queryValue,
            process.env.SECRET_KEY
          );
          const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
          const url = `${process.env.SISKOHAT_URL}/estimasi/${decryptedText}`;
          additionalSearchData = await fetch(url, {
            cache: "no-cache",
            method: "GET",
            headers: headersRequest,
            agent: new (require("https").Agent)({ rejectUnauthorized: false }), // Bypass SSL verification
          })
            .then((res) => {
              if (res.ok) return res.json();
              return null;
            })
            .catch((e) => {
              console.log(e);
              return null;
            });
          break;
        }
        case "pelunasan": {
          if (!queryValue) break;
          const headersRequest = {
            "Content-Type": "application/json",
            "x-key": process.env.XKEY_SISKOHAT,
          };
          const bytes = CryptoJS.AES.decrypt(
            queryValue,
            process.env.SECRET_KEY
          );
          const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
          const url = `${process.env.SISKOHAT_URL}/pelunasan/${decryptedText}`;
          additionalSearchData = await fetch(url, {
            cache: "no-cache",
            method: "GET",
            headers: headersRequest,
            agent: new (require("https").Agent)({ rejectUnauthorized: false }), // Bypass SSL verification
          })
            .then((res) => {
              if (res.ok) return res.json();
              return null;
            })
            .catch((e) => {
              console.log(e);
              return null;
            });
          break;
        }
        case "cancelation": {
          if (!queryValue) break;
          const headersRequest = {
            "Content-Type": "application/json",
            "x-key": process.env.XKEY_SISKOHAT,
          };
          const bytes = CryptoJS.AES.decrypt(
            queryValue,
            process.env.SECRET_KEY
          );
          const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
          const url = `${process.env.SISKOHAT_URL}/pembatalan/${decryptedText}`;
          additionalSearchData = await fetch(url, {
            cache: "no-cache",
            method: "GET",
            headers: headersRequest,
            agent: new (require("https").Agent)({ rejectUnauthorized: false }), // Bypass SSL verification
          })
            .then((res) => {
              if (res.ok) return res.json();
              return null;
            })
            .catch((e) => {
              console.log(e);
              return null;
            });
          break;
        }
        case "waiting-list": {
          const headersRequest = {
            "Content-Type": "application/json",
            "x-key": process.env.XKEY_SISKOHAT,
          };
          const url = `${process.env.SISKOHAT_URL}/wl`;
          additionalSearchData = await fetch(url, {
            cache: "no-cache",
            method: "GET",
            headers: headersRequest,
            agent: new (require("https").Agent)({ rejectUnauthorized: false }), // Bypass SSL verification
          })
            .then((res) => {
              if (res.ok) return res.json();
              return null;
            })
            .catch((e) => {
              console.log(e);
              return null;
            });
          break;
        }
        case "health-check": {
          if (!queryValue) break;
          const headersRequest = {
            "Content-Type": "application/json",
            "x-key": process.env.XKEY_SISKOHAT,
          };
          const bytes = CryptoJS.AES.decrypt(
            queryValue,
            process.env.SECRET_KEY
          );
          const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
          const url = `${process.env.SISKOHAT_URL}/cekkesehatan/${decryptedText}`;
          additionalSearchData = await fetch(url, {
            cache: "no-cache",
            method: "GET",
            headers: headersRequest,
            agent: new (require("https").Agent)({ rejectUnauthorized: false }), // Bypass SSL verification
          })
            .then((res) => {
              if (res.ok) return res.json();
              return null;
            })
            .catch((e) => {
              console.log(e);
              return null;
            });
          break;
        }

        default:
          break;
      }
    }

    const { data } = await response.json();
    return {
      props: {
        menu: data?.menu?.data,
        categories: data?.categoriesNotEqual?.data,
        recentNews: data?.articlesByCategory?.data,
        headlines: data?.headlines?.data,
        popularArticles: data?.popularArticles?.data,
        features: data?.features?.data,
        contact: data?.contact?.data,
        address: data?.address?.data,
        additionalSearchData: additionalSearchData,
      },
      status: 200,
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
    };
  }
}

export default IndexPage;
