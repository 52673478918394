import React, { useState } from "react";
import { motion } from "framer-motion";

const SelectOption = ({ data = [], value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const onSelect = (e) => {
    onChange && onChange(e);
    toggleDropdown();
  };

  return (
    <div className="relative inline-block w-full">
      <button
        className="text-md flex w-full flex-row items-center justify-between bg-transparent p-4 py-3 text-left font-semibold focus:outline-none md:mt-0 md:ml-4 md:inline md:w-auto"
        onClick={toggleDropdown}
      >
        {value.text || "Pilih opsi"}
        <svg
          fill="#333"
          viewBox="0 0 20 20"
          className={`inline h-7 w-7 transform duration-200 transition-transform md:-mt-1 ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      {isOpen && (
        <motion.div
          className="absolute z-50 mt-2 w-full border bg-white shadow-lg"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.2 }}
        >
          <ul className="py-1">
            {Array.isArray(data)
              ? data.map(({ value, text }, index) => {
                  return (
                    <li
                      key={`select-${value}-${text}-${index}`}
                      onClick={() => onSelect({ value, text })}
                      className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                    >
                      {text}
                    </li>
                  );
                })
              : null}
          </ul>
        </motion.div>
      )}
    </div>
  );
};

export default SelectOption;
