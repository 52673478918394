import React, { useState } from "react";
import Button from "../../common/button";
import { navigate } from "gatsby";

export default function SearchPIHK(props) {
  const [inputValue, setInputValue] = useState(null);

  return (
    <React.Fragment>
      <div className="grid sm:grid-cols-1 sm:py-4 md:grid-cols-5 md:py-6">
        <div className="flex flex-col justify-center sm:col-span-1 md:col-span-3 md:col-start-2 md:p-6">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (inputValue) {
                navigate(
                  `/search/pihk?q=${String(inputValue).toUpperCase()}`
                );
              }
            }}
            className="my-auto mr-4 flex w-full cursor-pointer flex-row justify-center whitespace-nowrap bg-transparent px-4 font-semibold text-primary transition-colors transition-100 transition-linear md:px-0 "
          >
            <input
              autoComplete="off"
              id="default-input"
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Masukkan Nama PIHK"
              className="text-md mr-4 block w-full border border-gray-300 bg-gray-50 p-2.5 text-sm font-normal text-fontPrimary focus:border-primary focus:outline-none focus:ring-primary"
            />
            <Button
              children={"Cari"}
              type="submit"
              className={"w-full md:w-44"}
              disabled={!inputValue}
            />
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
