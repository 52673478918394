import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useScroll } from "react-use-gesture";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "gatsby";
import { normalizeUrl } from "../utils";

const clamp = (value, clampAt = 30) => {
  if (value > 0) {
    return value > clampAt ? clampAt : value;
  } else {
    return value < -clampAt ? -clampAt : value;
  }
};

export default function Features({ data = [], ...props }) {
  const controls = useAnimation();
  const bind = useScroll((event) => {
    controls.start({
      transform: `perspective(500px) rotateY(${
        event.scrolling ? clamp(event.delta[0]) : 0
      }deg)`,
    });
  });

  return (
    <div
      data-sal="fade"
      data-sal-duration="300"
      data-sal-delay="100"
      className="whitespace-no-wrap overflow-x-auto"
      {...bind()}
    >
      <div className="inline-flex py-4">
        {data.map(({ attributes: item }, index) => (
          <motion.div
            key={`feature-item-${index}`}
            className="h-40 w-48 cursor-pointer text-center"
            animate={controls}
          >
            <Link to={item.url}>
              <LazyLoadImage
                className="mx-auto aspect-square object-cover transition-all transition-500 hover:scale-110"
                effect="blur"
                width={"70%"}
                alt={
                  item.image?.data?.attributes?.caption ||
                  item.image?.data?.attributes?.alternativeText ||
                  item.title
                }
                src={normalizeUrl(item.image?.data?.attributes?.url)}
              />
              <p className="mt-2 text-center line-clamp-2">{item.title}</p>
            </Link>
          </motion.div>
        ))}
      </div>
    </div>
  );
}
