import React, { useState, useMemo, useEffect } from "react";
import { motion } from "framer-motion";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import CryptoJS from 'crypto-js';
import { navigate } from "gatsby";
import Button from "../../common/button";

const tabContentVariants = {
  initial: { opacity: 0, y: -10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
};
export default function HealtCheck(props) {
  const [inputValue, setInputValue] = useState(null);
  const [inputCaptchaValue, setInputCaptchaValue] = useState(null);

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, [props?.location?.search])

  const result = useMemo(() => {
    const search = String(props?.location?.search);
    const regex = /health(-check)?/i;
    const activeTab = regex.test(search);

    if (props?.additionalSearchData && activeTab) {
      return props?.additionalSearchData?.Data[0];
    }
    return null;
  }, [props?.additionalSearchData, props?.location?.search]);

  const error = useMemo(() => {
    const search = String(props?.location?.search);
    const regex = /health-check&q=?/i;
    const hasQueryValue = regex.test(search);

    return hasQueryValue && !result ;
  }, [props?.location?.search, result]);


  const search = () => {
    const currentSearch = window.location.search;
    const queryParams = new URLSearchParams(currentSearch);
    const encryptedValue = CryptoJS.AES.encrypt(inputValue, process.env.SECRET_KEY).toString();
    queryParams.set("search", "health-check");
    queryParams.set("q", encryptedValue);
    const newSearch = queryParams.toString();
    navigate(`/?${newSearch}`);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const hasValidCaptcha = validateCaptcha(inputCaptchaValue);
    if (hasValidCaptcha) {
      return search();
    }
    return
  }

  return (
    <React.Fragment>
      <div className="grid sm:grid-cols-1 sm:py-4 md:grid-cols-5 md:py-6">
        <div className="flex flex-col justify-center sm:col-span-1 md:col-span-3 md:col-start-2 md:p-6">
          <form
            onSubmit={onSubmit}
            className="my-auto flex-col lg:flex-row mr-4 flex w-full cursor-pointer flex-row justify-center whitespace-nowrap bg-transparent px-4 font-semibold text-primary transition-colors transition-100 transition-linear md:px-0 "
          >
            <input
              autoComplete="off"
              type="number"
              id="default-input"
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Masukkan Nomor Porsi"
              className="text-md mr-4 h-11 block w-full border border-gray-300 bg-gray-50 p-2.5 text-sm font-normal text-fontPrimary focus:border-primary focus:outline-none focus:ring-primary"
            />
            <div className="lg:mr-4 mr-0">
              <input
                autoComplete="off"
                onChange={(e) => setInputCaptchaValue(e.target.value)}
                type="text"
                id="captcha-input"
                placeholder="Masukkan captcha"
                className="text-md mr-4 block h-11 w-full border border-gray-300 bg-gray-50 p-2.5 text-sm font-normal text-fontPrimary focus:border-primary focus:outline-none focus:ring-primary"
              />
              <LoadCanvasTemplate className="flex flex-row" reloadColor="#10AC84" />
            </div>
            <Button
              children={"Cari"}
              type="submit"
              className={"w-full md:w-44 h-11 sm:mt-5 md:mt-0"}
              disabled={!inputValue}
            />
          </form>
          {error && (
            <div className="px-4 text-red-800 md:px-0">
              Data tidak ditemukan
            </div>
          )}
        </div>
      </div>
      <Result data={result} />
    </React.Fragment>
  );
}

const Result = ({ data }) => {
  if (!data) return null;
  return (
    <motion.div
      key={1}
      variants={tabContentVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      className="grid border-gray-300 p-4 sm:grid-cols-1 sm:border-t md:grid-cols-2 md:px-6"
    >
      <div className="col">
        <div className="font-bold">Nama</div>
        <p>{data.nama_jemaah}</p>
        <div className="mt-2 font-bold">Nomor Porsi</div>
        <p>{data.no_porsi}</p>
        <div className="mt-2 font-bold">Tanggal Pemeriksaan</div>
        <p>
        {new Date(data.tgl_pemeriksaan).toLocaleDateString("id-ID", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </p>
      </div>
      <div className="col sm:mt-2 md:mt-0">
        <div className="mt-2 font-bold">Tempat Pemeriksaan</div>
        <p>{data.tempat_pemeriksaan}</p>
        <div className="font-bold">Penetapan Risti</div>
        <p>{data.jenis_risti}</p>
        <div className="mt-2 font-bold">Penetapan Istithaah</div>
        <p>{data.jenis_istithaah}</p>
      </div>
    </motion.div>
  );
};
