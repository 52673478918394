import React, { useEffect, useMemo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TitleGroup from "./common/title-group";
import { Link } from "./common/link";
import Item from "./common/item";
import { normalizeUrl } from "../utils";

export default function News({ data = [] }) {

  const tags = useMemo(() => {
    // if (data && Array.isArray(data) && data.length > 0) {
    //   return data.reduce((acc, curr) => {
    //     const { tag } = curr?.attributes || { tag: null };
    //     if(tag && !!tag?.data && !!tag?.data?.attributes) {
    //       const value = tag?.data?.attributes;
    //       const existing = acc.find((item) => item?.slug === value?.slug);
    //       if (Array.isArray(acc) && !existing) {
    //         acc.push({...value, url: `/tag/${value?.slug}`});
    //       }
    //     }
    //     return acc;
    //   }, []);
    // }
    // return [];
    return [
      {
          "name": "Daerah",
          "slug": "daerah",
          "color": "#670075",
          "url": "/tag/daerah"
      },
      {
          "name": "Nasional",
          "slug": "nasional",
          "color": "#333333",
          "url": "/tag/nasional"
      }
    ];
  }, [data]);

	if (!data || !Array.isArray(data) || data.length === 0) return null;
	const [{ attributes: firstArticle }, ...restArticles] = data;
	const firstArticleUrl = firstArticle.url || `/detail/${firstArticle?.slug}`;
  const subtitleFirstArticle = useMemo(() => {
    if (Array.isArray(firstArticle?.blocks)) {
      const richText = Array(...firstArticle?.blocks).find(
        (block) => block?.["__typename"] === "ComponentSharedRichText"
      );
      return richText?.body;
    }
    return firstArticle?.description;
  }, [firstArticle])

  return (
    <div data-sal="fade" data-sal-duration="300" data-sal-delay="100">
      <TitleGroup title="Berita" tags={tags} />
      <div className="my-4 grid grid-cols-1 gap-5 md:my-6 md:grid-cols-5 md:gap-10">
        <Link
          to={firstArticleUrl}
          className="col-span-1 transition-all transition-200 hover:scale-105 md:col-span-3"
        >
          <LazyLoadImage
            className="mx-auto aspect-video object-cover"
            effect="blur"
            width={"100%"}
            alt={firstArticle.title}
            src={normalizeUrl(firstArticle.cover?.data?.attributes?.url)}
          />
          <p className="mt-3 text-xs text-gray-400">
            {new Date(firstArticle.date).toLocaleDateString("id-ID", {
              dateStyle: "medium",
            })}
          </p>
          <h1 className="line-clamp-2">{firstArticle.title}</h1>
          <p className="mt-3 text-sm italic text-gray-400 line-clamp-6">
            {subtitleFirstArticle}
          </p>
        </Link>
        <div className="col-span-1 flex flex-col justify-between md:col-span-2">
          {restArticles.map(({ attributes: item }, index) => {
            const url = item.url || `/detail/${item?.slug}`;
            const imageAlt =
              item.cover?.data?.attributes?.alternativeText ||
              item.cover?.data?.attributes?.caption ||
              item.title;
            return (
              <Item
                key={`news-item-${index}`}
                image={item.cover?.data?.attributes?.url}
                imageAlt={imageAlt}
                label={new Date(item.date).toLocaleDateString("id-ID", {
                  dateStyle: "medium",
                })}
                title={item.title}
                url={url}
                height="64px"
                width="96px"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
