import React from "react";
import Slider from "react-slick";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "./common/link";
import TitleGroup from "./common/title-group";
import { normalizeUrl } from "../utils";

const Headline = ({ data = [], popularArticles, ...props }) => {
  if (
    !data ||
    !Array.isArray(data) ||
    !popularArticles ||
    !Array.isArray(popularArticles)
  )
    return null;

  return (
    <div
      data-sal="fade"
      data-sal-duration="300"
      data-sal-delay="100"
      className="grid grid-cols-1 gap-y-5 py-4 md:grid-cols-6 md:gap-10"
    >
      <Slider
        lazyLoad="ondemand"
        className="col-span-4 aspect-video w-full"
        dots={false}
        infinite={true}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={false}
        autoplay
        swipe={true}
      >
        {Array.isArray(data) && data.map((row, index) => {
          const item = row?.attributes?.article?.data?.attributes;
          if(!item) return null;
          const url = item.url || `/detail/${item.slug}`;
          return (
            <Link
              className="relative cursor-pointer"
              to={url}
              key={`slider-item-${index}`}
            >
              <LazyLoadImage
                className="aspect-video object-cover"
                effect="blur"
                key={index}
                height={"100%"}
                width={"100%"}
                alt={item.title}
                loading="eager"
                src={normalizeUrl(item.cover?.data?.attributes?.url)}
              />
              <div className="absolute bottom-5 left-5 md:bottom-10 md:left-10">
                <h1 className="text-gray-300">
                  {new Date(item.date).toLocaleDateString("id-ID", {
                    dateStyle: "medium",
                  })}
                </h1>
                <h1 className="sm:text-lg md:text-2xl text-white">{item.title}</h1>
              </div>
            </Link>
          );
        })}
      </Slider>
      <div className="flex-start md:col-span-2 flex flex-col">
        <TitleGroup title="Populer" />
        {popularArticles.map((row, index) => {
          if(!row) return null;
          const { attributes: item } = row;
          const url = item?.url || `/detail/${item?.slug}`;
          const imageUrl = item.cover?.data?.attributes?.url;
          return (
            <Link
              key={`headline-${index}`}
              to={url}
              className={`flex flex-row gap-2 py-2 transition-all transition-200 hover:scale-105`}
            >
              {imageUrl ? (
                <LazyLoadImage
                  className={"min-w-[96px] h-[64px] object-cover"}
                  effect="blur"
                  src={normalizeUrl(imageUrl)}
                  alt={
                    item.cover?.data?.attributes?.alternativeText ||
                    item.cover?.data?.attributes?.caption ||
                    item.title
                  }
                  height="64px"
                  width="96px"
                />
              ) : <div className="h-16"/>}
              <div className={`line-clamp-2`}>
                <span className="mt-2 text-xs text-gray-400">
                  {new Date(item.date).toLocaleDateString("id-ID", {
                    dateStyle: "medium",
                  })}
                </span>
                <div className="col-span-1 text-sm mr-2 pb-2 text-fontPrimary">
                  {item.title}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Headline;
