import React, { useState, createRef, useMemo, useEffect } from "react";
import { motion } from "framer-motion";
import Estimation from "./components/estimation";
import SelectOption from "../common/select-option";
import Cancelation from "./components/cancelation";
import SearchPIHK from "./components/search-pihk";
import SearchPPIU from "./components/search-ppiu";
import Motion from "../common/motion";
import WaitingList from "./components/waiting-list";
import HealtCheck from "./components/health-check";
import Pelunasan from "./components/pelunasan";
import { navigate } from "gatsby";

export default function SearchForm(props) {
  const tabs = [
    { text: "Estimasi Keberangkatan", value: "estimation" },
    { text: "Pelunasan", value: "pelunasan" },
    { text: "Daftar Tunggu", value: "waiting-list" },
    { text: "Pembatalan", value: "cancelation" },
    { text: "PIHK", value: "pihk" },
    { text: "PPIU", value: "ppiu" },
    { text: "Isthitaah Kesehatan", value: "health-check" },
  ];
  // const [activeTab, setActiveTab] = useState(0);
  const tabRefs = tabs.map(() => createRef(null));
  const [indicatorWidth, setIndicatorWidth] = useState(0);
  const [indicatorOffset, setIndicatorOffset] = useState(0);

  const activeTab = useMemo(() => {
    const queryParams = new URLSearchParams(props?.location.search);
    if (!queryParams.get("search")) return 0;
    return tabs.findIndex((tab) => tab.value === queryParams.get("search"));
  }, [props?.location?.search]);

  useEffect(() => {
    const tabElement = document.getElementById("search-tabs"); // Assuming the tab content has an id matching the tab name
    if (tabElement && props?.location?.search) {
      const behavior = "smooth";
      const topOffset =
        tabElement.getBoundingClientRect().top + window.scrollY - 100;
      window.scrollTo({ top: topOffset, behavior });
    }
  }, [activeTab, props?.location?.search]);

  const handleTabClick = (value) => {
    navigate(`?search=${value.value}`, { replace: true });
  };

  useEffect(() => {
    if (tabRefs[activeTab].current) {
      const tabWidth = tabRefs[activeTab].current.clientWidth;
      const tabOffset = tabRefs[activeTab].current.offsetLeft;
      setIndicatorWidth(tabWidth);
      setIndicatorOffset(tabOffset);
    }
  }, [activeTab, tabRefs]);

  const content = useMemo(() => {
    switch (activeTab) {
      case 0:
        return (
          <Motion>
            <Estimation {...props} />
          </Motion>
        );
      case 1:
        return (
          <Motion>
            <Pelunasan {...props} />
          </Motion>
        );
      case 2:
        return (
          <Motion>
            <WaitingList {...props} />
          </Motion>
        );
      case 3:
        return (
          <Motion>
            <Cancelation {...props} />
          </Motion>
        );
      case 4:
        return (
          <Motion>
            <SearchPIHK {...props} />
          </Motion>
        );
      case 5:
        return (
          <Motion>
            <SearchPPIU {...props} />
          </Motion>
        );
      case 6:
        return (
          <Motion>
            <HealtCheck {...props} />
          </Motion>
        );


      default:
        return <div className="h-[260px]" />;
    }
  }, [activeTab, props]);

  return (
    <div className="my-4 h-auto w-full" id="search-tabs">
      <div className="relative flex flex-row items-start self-start overflow-x-auto bg-secondary sm:hidden md:flex">
        {tabs.map((tab, index) => (
          <div
            ref={tabRefs.at(index)}
            key={index}
            className="z-20 w-full cursor-pointer whitespace-nowrap px-6 py-3 md:w-auto"
            onClick={() => handleTabClick(tab)}
          >
            {tab.text}
          </div>
        ))}
        <motion.div
          className="tab-indicator sm:border-top-primary"
          layoutId="indicator"
          initial={false}
          animate={{
            x: indicatorOffset,
            width: indicatorWidth,
          }}
          transition={{ type: "tween" }}
        />
      </div>
      <div className="border-b border-gray-300 bg-onSecondary md:hidden">
        <SelectOption
          data={tabs}
          value={tabs[activeTab]}
          onChange={(value) => handleTabClick(value)}
        />
      </div>
      <div className="bg-onSecondary">{content}</div>
    </div>
  );
}
