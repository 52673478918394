import React, { useMemo, useEffect } from "react";
import { motion } from "framer-motion";
import Table from "../../table";
import { navigate } from "gatsby";

const tabContentVariants = {
  initial: { opacity: 0, y: -10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
};
export default function WaitingList(props) {
  useEffect(() => {
    const searchParams = new URLSearchParams(props?.location?.search)
    const search = searchParams.get("search")
    
    if(!search) {
      console.log(props?.location);
      navigate('/?search=waiting-list')
    }
  }, [props?.location?.search])

  const result = useMemo(() => {
    if (props?.additionalSearchData && Array.isArray(props?.additionalSearchData?.Data)) {
      return props?.additionalSearchData?.Data.map(item => {
        return { 
          ...item,
          kuota: Number(item?.kuota).toLocaleString('id-ID'),
          jumlah_pendaftar: Number(item?.jumlah_pendaftar).toLocaleString('id-ID'),
          lunas_tunda: Number(item?.lunas_tunda).toLocaleString('id-ID'),
        }
      });
    }
    return [];
  }, [props?.additionalSearchData]);

  const error = useMemo(() => {
    return props?.location?.search && !result;
  }, [props?.location?.search, result]);

  return (
    <React.Fragment>
      <div className="grid sm:grid-cols-1 sm:pt-4 md:grid-cols-5 md:py-6">
        <div className="flex flex-col justify-center sm:col-span-1 md:col-span-3 md:p-6">
          {/* {loading && (<CircleLoading/>)} */}
          {error && (
            <div className="px-4 text-red-800 md:px-0">
              Data tidak ditemukan
            </div>
          )}
          {result && !error ?<Table data={result} options={[
            { key: "wilayah", label: "Wilayah"},
            { key: "kuota", label: "Kuota", className: "text-right"},
            { key: "masa_tunggu", label: "Tahun", className: "text-right"},
            { key: "porsi_terakhir", label: "Porsi Terakhir"},
            { key: "jumlah_pendaftar", label: "Jumlah Pendaftar", className: "text-right"},
            { key: "lunas_tunda", label: "Lunas Tunda", className: "text-right"},
          ]} /> : null}
        </div>
      </div>
    </React.Fragment>
  );
}

const Result = ({ data }) => {
  if (!data) return null;
  return (
    <motion.div
      key={1}
      variants={tabContentVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      className="grid border-gray-300 p-4 sm:grid-cols-1 sm:border-t md:grid-cols-6 md:px-6"
    >
      <Table data={data} options={[
        { key: "wilayah", label: "Wilayah"},
        { key: "kuota", label: "Kuota"},
        { key: "masa_tunggu", label: "Tahun"},
        { key: "porsi_terakhir", label: "Porsi Terakhir"},
        { key: "jumlah_pendaftar", label: "Jumlah Pendaftar"},
        { key: "lunas_tunda", label: "Lunas Tunda"},
      ]} />
      {/* <div className="col-span-2">
        <div className="font-bold">Nama</div>
        <p className="">{data.nama}</p>
        <div className="mt-2 font-bold">Kabupaten</div>
        <p className="">{data.kabupaten}</p>
      </div>
      <div className="col-span-2">
        <div className="font-bold">Provinsi</div>
        <p className="">{data.propinsi}</p>
        <div className="mt-2 font-bold">Kuota Provinsi/Kab/Kota/Khusus</div>
        <p className="">{data.posisiporsi}</p>
      </div>
      <div className="col-span-2">
        <div className="font-bold">Estimasi Keberangkatan</div>
        {data.berangkathijriah}H / {data.berangkatmasehi}M
        <div className="mt-2 font-bold">Waktu Permintaan Informasi</div>
        <p className="">
          {new Date().toLocaleString("id-ID", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZoneName: "short",
          })}
        </p>
      </div> */}
    </motion.div>
  );
};
