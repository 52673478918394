export const articlesByCategory = `
articlesByCategory: articles (
  publicationState: LIVE
  filters: { category: { slug: { eq: $articlesByCategory } } }
  sort: "date:DESC",
  pagination: {
    limit: $articlesByCategoryLimit
  }
) {
  data {
    attributes {
      title
      description
      date
      url
      slug
      blocks {
        __typename
        ... on ComponentSharedRichText {
          body
        }
      }
      cover {
        data {
          attributes {
            url
            alternativeText
            caption
          }
        }
      }
      tag {
        data {
          attributes {
            name
            slug
            color
          }
        }
      }
    }
  }
}
`;
export const articlesByCategoryWithPagination = `
articlesByCategoryWithPagination: articles (
  publicationState: LIVE
  filters: { category: { slug: { eq: $articlesByCategoryWithPaginationCategory } } }
  sort: "date:DESC",
  pagination: {
    page: $articlesByCategoryWithPaginationPage
    pageSize: $articlesByCategoryWithPaginationPageSize
  }
) {
  data {
    attributes {
      title
      description
      date
      url
      slug
      blocks {
        __typename
        ... on ComponentSharedRichText {
          body
        }
      }
      cover {
        data {
          attributes {
            url
            alternativeText
            caption
          }
        }
      }
      tag {
        data {
          attributes {
            name
            slug
            color
          }
        }
      }
    }
  }
}
`;
export const popularArticles = `
popularArticles: articles (
  publicationState: LIVE
  sort: "views:DESC",
  pagination: {
    limit: $popularArticlesLimit
  },
  filters: {
    category: {
      slug: {
        eq: "berita"
      }
    },
    publishedAt: {
      gte: $popularArticlesMaxDate
    }
  }
) {
  data {
    attributes {
      title
      slug
      url
      date
      publishedAt
      cover {
        data {
          attributes {
            url
            alternativeText
            caption
          }
        }
      }
      category{
        data{
          attributes{
            name
          }
        }
      }
    }
  }
}
`;
export const articleBySlug = `
articleBySlug: articles(
  publicationState: LIVE
  filters: {
    slug: { eq: $articleBySlug }
  }
) {
  data {
    attributes {
      title
      description
      date
      url
      slug
      views
      createdBy {
        firstName
        lastName
      }
      category{
        data{
          attributes{
            name
            slug
          }
        }
      }
      blocks {
        __typename
        ... on ComponentSharedMedia {
          file {
            data {
              attributes {
                mime
                url
                caption
                alternativeText
                name
              }
            }
          }
        }
        ... on ComponentSharedRichText {
          body
        }
        ... on ComponentSharedQuote {
          title
          body
        }
        ... on ComponentSharedSlider {
          files {
            data {
              attributes {
                name
                alternativeText
                caption
                url
              }
            }
          }
        }
        ... on ComponentSharedYoutube {
          url
        }
        ... on ComponentSharedGridMedia {
          images {
            data {
              attributes {
                name
                alternativeText
                caption
                url
              }
            }
          }
        }
      }
      cover {
        data {
          attributes {
            url
            alternativeText
            caption
          }
        }
      }
      tag {
        data {
          attributes {
            name
            slug
            color
          }
        }
      }
    }
  }
}
`;
export const articleSearchByTitle = `
articleSearchByTitle: articles(
  publicationState: LIVE
  filters: {
    title: { containsi: $articleSearchByTitle }
    category: { slug: { eq: $articleSearchByTitleCategory } }
  }
  pagination: {
    page: $articleSearchByTitlePage
    pageSize: $articleSearchByTitlePageSize
  }
) {
  data {
    attributes {
      title
      date
      url
      slug
      views
      category{
        data{
          attributes{
            name
            slug
          }
        }
      }
      blocks {
        __typename
        ... on ComponentSharedRichText {
          body
        }
      }
      cover {
        data {
          attributes {
            url
            alternativeText
            caption
          }
        }
      }
      tag {
        data {
          attributes {
            name
            slug
            color
          }
        }
      }
    }
  }
}
`;
export const articlesByTag = `
articlesByTag: articles(
  publicationState: LIVE
  filters: { tag: { slug: { eq: $articlesByTag } } }
  sort: "date:DESC",
  pagination: {
    page: $articlesByTagPage
    pageSize: $articlesByTagPageSize
  }
) {
  data {
    attributes {
      title
      description
      date
      url
      slug
      views
      createdBy {
        firstName
        lastName
      }
      category{
        data{
          attributes{
            name
            slug
          }
        }
      }
      blocks {
        __typename
        ... on ComponentSharedRichText {
          body
        }
      }
      cover {
        data {
          attributes {
            url
            alternativeText
            caption
          }
        }
      }
      tag {
        data {
          attributes {
            name
            slug
            color
          }
        }
      }
    }
  }
}
`;
export const articlesWithNoTag = `
articlesWithNoTag: articles(
  publicationState: LIVE
  filters: { tag: { slug: { null: true } } }
  sort: "date:DESC",
  pagination: {
    page: $articlesWithNoTagPage
    pageSize: $articlesWithNoTagPageSize
  }
) {
  data {
    attributes {
      title
      description
      date
      url
      slug
      views
      createdBy {
        firstName
        lastName
      }
      category{
        data{
          attributes{
            name
            slug
          }
        }
      }
      blocks {
        __typename
        ... on ComponentSharedRichText {
          body
        }
      }
      cover {
        data {
          attributes {
            url
            alternativeText
            caption
          }
        }
      }
    }
  }
}
`;
export const articlesWithNoTagByCategory = `
articlesWithNoTagByCategory: articles(
  publicationState: LIVE
  filters: {
    and: [
      {
        tag: { slug: {null: true}}
      },
      {
        category: {
          slug: { eq: $articlesWithNoTagByCategorySlug }
        }
      }
    ]
  },
  sort: "date:DESC",
  pagination: {
    page: $articlesWithNoTagByCategoryPage
    pageSize: $articlesWithNoTagByCategoryPageSize
  }
) {
  data {
    attributes {
      title
      description
      date
      url
      slug
      views
      createdBy {
        firstName
        lastName
      }
      category{
        data{
          attributes{
            name
            slug
          }
        }
      }
      blocks {
        __typename
        ... on ComponentSharedRichText {
          body
        }
      }
      cover {
        data {
          attributes {
            url
            alternativeText
            caption
          }
        }
      }
    }
  }
}
`;
export default articlesByCategory;
