import React, { useState, useMemo, useEffect } from "react";
import { motion } from "framer-motion";
import { navigate } from "gatsby";
import CryptoJS from "crypto-js";
import Button from "../../common/button";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

const tabContentVariants = {
  initial: { opacity: 0, y: -10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
};
export default function Estimation(props) {
  const [inputValue, setInputValue] = useState(null);
  const [inputCaptchaValue, setInputCaptchaValue] = useState(null);

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, [props?.location?.search]);

  const result = useMemo(() => {
    const search = String(props?.location?.search);
    const regex = /estimation/i;
    const activeTab = regex.test(search);

    if (props?.additionalSearchData && activeTab) {
      return props?.additionalSearchData?.Data[0];
    }
    return null;
  }, [props?.additionalSearchData, props?.location?.search]);

  const error = useMemo(() => {
    const search = String(props?.location?.search);
    const regex = /estimation&q=?/i;
    const hasQueryValue = regex.test(search);

    return hasQueryValue && !result;
  }, [props?.location?.search, result]);

  const search = () => {
    const currentSearch = window.location.search;
    const queryParams = new URLSearchParams(currentSearch);
    const encryptedValue = CryptoJS.AES.encrypt(
      inputValue,
      process.env.SECRET_KEY
    ).toString();
    queryParams.set("search", "estimation");
    queryParams.set("q", encryptedValue);
    const newSearch = queryParams.toString();
    navigate(`/?${newSearch}`);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const hasValidCaptcha = validateCaptcha(inputCaptchaValue);
    if (hasValidCaptcha) {
      return search();
    }
    return;
  };

  return (
    <React.Fragment>
      <div className="grid sm:grid-cols-1 sm:pt-4 md:grid-cols-5 md:py-6">
        <div className="flex flex-col justify-center sm:col-span-1 md:col-span-3 md:p-6">
          <form
            onSubmit={onSubmit}
            className="my-auto flex w-full cursor-pointer flex-col justify-center whitespace-nowrap bg-transparent px-4 font-semibold text-primary transition-colors transition-100 transition-linear md:px-0 lg:flex-row "
          >
            <input
              autoComplete="off"
              type="number"
              id="default-input"
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Masukkan Nomor Porsi"
              className="text-md mr-4 block h-11 w-full border border-gray-300 bg-gray-50 p-2.5 text-sm font-normal text-fontPrimary focus:border-primary focus:outline-none focus:ring-primary"
            />
            <div className="mr-0 lg:mr-4">
              <input
                autoComplete="off"
                onChange={(e) => setInputCaptchaValue(e.target.value)}
                type="text"
                id="captcha-input"
                placeholder="Masukkan captcha"
                className="text-md mr-4 block h-11 w-full border border-gray-300 bg-gray-50 p-2.5 text-sm font-normal text-fontPrimary focus:border-primary focus:outline-none focus:ring-primary"
              />
              <LoadCanvasTemplate
                className="flex flex-row"
                reloadColor="#10AC84"
              />
            </div>

            <Button
              children={"Cari"}
              type="submit"
              className={"h-11 w-full sm:mt-5 md:mt-0 md:w-44"}
              disabled={!inputValue}
            />
          </form>
          {error && (
            <div className="px-4 text-red-800 md:px-0">
              Data tidak ditemukan
            </div>
          )}
        </div>

        <div className="col-span-1 border-gray-300 p-4 md:col-span-2 md:border-l">
          <h1 className="text-md font-bold">*Disclaimer</h1>
          <p className="mt-2 text-sm">
            Perkiraan keberangkatan dapat berubah sesuai perubahan kuota
            provinsi/kab/kota/haji khusus dan perubahan regulasi dan hanya
            dihitung untuk jemaah yang belum batal atau belum berangkat
          </p>
        </div>
      </div>
      <Result data={result} />
    </React.Fragment>
  );
}

const Result = ({ data }) => {
  if (!data) return null;
  return (
    <motion.div
      key={1}
      variants={tabContentVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      className="grid border-gray-300 p-4 sm:grid-cols-1 sm:border-t md:grid-cols-6 md:px-6"
    >
      <div className="col-span-2">
        <div className="font-bold">Nama</div>
        <p className="">{data.nama}</p>
        <div className="mt-2 font-bold">Kabupaten</div>
        <p className="">{data.kabupaten}</p>
      </div>
      <div className="col-span-2">
        <div className="font-bold">Provinsi</div>
        <p className="">{data.propinsi}</p>
        <div className="mt-2 font-bold">Kuota Provinsi/Kab/Kota/Khusus</div>
        <p className="">{data.kuotapropinsi}</p>
      </div>
      <div className="col-span-2">
        <div className="font-bold">Status Bayar</div>
        <p className="">{data.status_bayar}</p>
        <div className="font-bold">Estimasi Keberangkatan</div>
        {data.berangkathijriah}H / {data.berangkatmasehi}M
        <div className="mt-2 font-bold">Waktu Permintaan Informasi</div>
        <p className="">
          {new Date().toLocaleString("id-ID", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZoneName: "short",
          })}
        </p>
      </div>
    </motion.div>
  );
};
