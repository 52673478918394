import { useState, useEffect } from "react";

export default function useFetch(
  url,
  body,
  options = {
    requestOptions: {},
    dependencies: [],
    runOnFirstMount: true,
  }
) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const controller = new AbortController();
  const signal = controller.signal;
  const defaultOptions = {
    cache: "no-cache",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    ...options.requestOptions,
  };
  const fetchData = () => {
    if (!url) return;
    setLoading(true);
    setData(null);
    setError(null);
    fetch(url, { ...defaultOptions, body, signal })
      .then((res) => {
        if (!res.ok) {
          let err = new Error("HTTP status code: " + res.status);
          err.response = res;
          err.status = res.status;
          throw err;
        }
        return res.json();
      })
      .then((res) => {
        setData(res.data || res);
        setError(null);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, options.dependencies || []);

  return { loading, data, error, fetchData, controller };
}
