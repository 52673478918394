import React, { useMemo } from "react";
import Slider from "react-slick";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Tag from "./common/tag";
import { Link } from "./common/link";
import { formatGQLQuery, normalizeUrl } from "../utils";
import { articlesByCategory } from "../graphql/article";
import useFetch from "../hooks/useFetch";

const GridCardSlider = ({ data, ...props }) => {
  if (!data || !Array.isArray(data)) return null;

  return (
    <div
      data-sal="fade"
      data-sal-duration="300"
      data-sal-delay="100"
      className="grid grid-cols-1 gap-5 border-t border-gray-300 py-4 md:my-6 md:grid-cols-2 md:gap-10 md:py-6"
    >
      {data.map(({ attributes: item }, index) => {
        return (
          <CardSlider
            slug={item?.slug}
            title={item?.name}
            url={`/${item?.slug}`}
            key={`grid-card-slider-${index}`}
            {...props}
          />
        );
      })}
    </div>
  );
};

function CardSlider({ slug, title, url = "#" }) {
  const bodyRequest = useMemo(() => {
    return JSON.stringify({
      query: formatGQLQuery(
        [articlesByCategory],
        [
          { key: "$articlesByCategory", type: "String!" },
          { key: "$articlesByCategoryLimit", type: "Int!" },
        ]
      ),
      variables: {
        articlesByCategory: slug,
        articlesByCategoryLimit: 10,
      },
    });
  }, [slug]);

  const {
    loading,
    data: response,
    error,
  } = useFetch(!slug ? null : process.env.GRAPHQL_URL, bodyRequest, {
    dependencies: [slug],
  });

  const data = useMemo(() => {
    if(!!response && Array.isArray(response?.articlesByCategory?.data)) {
      return response?.articlesByCategory?.data;
    }
    return null
  }, [response])
  
  if (!slug || loading || error || !data ) return null;
  if (Array.isArray(data) && data.length === 0) return null
  return (
    <div className="relative col-span-1 transition-all transition-200 hover:scale-105">
      <Tag
        url={url}
        text={title}
        color="primary"
        className="absolute top-5 left-5 z-50"
      />
      <Slider
        lazyLoad="ondemand"
        className="col-span-3 aspect-video w-full"
        dots={false}
        infinite={true}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={false}
        autoplay
        swipe={true}
      >
        {data.map(({ attributes: item }, index) => {
          const hasImage = !!item.cover.data?.attributes.url;
          const titleContainerClass = hasImage ? "bottom-0 bg-black/50 p-2" : "top-14";
          const titleClass = `sm:text-md md:text-xl ${hasImage ? "text-white" : "text-black"}`
          const url = item.url || `/detail/${item?.slug}`;
          return (
            <Link className="relative" to={url} key={`slider-item-${index}`}>
              <LazyLoadImage
                className="aspect-video object-cover"
                effect="blur"
                key={index}
                height={"100%"}
                width={"100%"}
                alt={item.title}
                src={normalizeUrl(item.cover.data?.attributes.url)}
              />
              <div className={`absolute left-0 right-0 ${titleContainerClass}`}>
                <h1 className={titleClass}>{item.title}</h1>
              </div>
            </Link>
          );
        })}
      </Slider>
    </div>
  );
}

export default GridCardSlider;
